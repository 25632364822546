const ENDPOINTS = {
  getProductAllimages: '/product-all-images',
  brandsList: '/admin/brands',
  brandOptions: '/admin/brand-options',
  newBrandsList: '/admin/new-brands',
  brandXPathByTitle: '/admin/xpathPattern',
  createUpdateXPathPattern: '/admin/xpathPattern',
  removePattern: '/admin/xpathPattern',
  brandsChangelog: '/admin/brands-changelog',
  subBrandsList: '/admin/brands/subbrands',
  networkTransactions: '/admin/transactions',
  exportNetworkTransactions: '/admin/custom/transactions/export',
  updateNetworkTransactions: '/admin/transactions/:id',
  revenues: '/admin/revenues',
  shortenLink: '/brands/shortLinks',
  getShortLinks: '/admin/short-links',
  parseProduct: '/parse-product-link',
  getJoinPages: '/join-pages',
  updateJoinPages: '/join-pages/update',
  memberStatistics: '/users/memberStatistics',
  members: {
    all: '/admin/users',
    getExtensionData: '/admin/custom/user/:id/extension-info',
    api2All: '/admin/custom/users',
    api2Export: '/admin/custom/users/export',
    downloadExportFile: '/admin/users/download',
    updateMember: '/admin/users/:id',
    changeUserStatus: '/admin/users/:id/status',
    togglePaymentHeld: '/admin/users/:id/togglePaymentHeld',
    changeIsPro: '/admin/users/:id/pro',
    changeIsShownInTheChirpyest: '/admin/users/:id/is-theChirpyest',
    changeIsCreator: '/admin/users/:id/is-creator',
    changeIsNewsletter: '/admin/users/:id/is-newsletter',
    referralBonusStatus: '/admin/users/:id/is-referral-bonus',
    updateProSharePercent: '/admin/users/:id/pro-share-percent',
    updateRoles: '/admin/users/:id/roles',
  },
  brands: {
    brandUsers: '/brand/:id/brand-users',
    toggleBrandShownInExtension: '/admin/brands/:id/toggleShownInExtension',
    toggleSubBrandShownInExtension:
      '/admin/brands/subbrands/:id/toggleShownInExtension',
  },
  exports: {
    createExportLog: '/export-logs',
    getExports: '/export-logs/all',
  },
  payments: {
    all: '/admin/payments',
    readyToPay: '/admin/payments/ready-to-pay',
    sendPayouts: '/admin/send-payments',
    referrals: '/admin/referrals-payments',
    referralsToPay: '/admin/payments/referrals-to-pay',
    pendingReferrals: '/admin/payments/pending-referrals',
    sendReferralsPayments: '/admin/send-referrals-payments',
    paymentHeldLogs: '/admin/payment-held-logs',
  },
  proShareCommissions: {
    all: '/admin/pro-shared-commission',
    readyToPay: '/admin/pro-shared-commission/ready-to-pay',
    sendPayouts: '/admin/pro-shared-commission/sendPayments',
  },
  settings: {
    all: '/settings',
    updateSettings: '/settings/:name',
    customCommissionSplit: '/admin/custom-split-percent',
    adminRole: '/settings/admin-type',
    promptText: '/settings/prompt-text',
  },
  subscription: {
    all: '/subscription',
    brandsList: '/admin/brands-list',
  },
  admins: {
    all: '/admin/admins',
    deleteAdmin: '/admin/admins/:id',
    editAdmin: '/admin/admins/:id',
    search: '/admin/users/search',
  },
  banner: {
    all: '/admin/banner',
    deleteBanner: '/banner/:id',
    editBanner: '/banner/:id',
    addBanner: '/banner',
    updatePosition: '/banner/:id/update-position',
  },
  categories: {
    all: '/admin/categories',
    topProductsByPage: '/top-category-products/:categoryPage',
    topProductsById: '/top-category-products/:id',
    topProducts: '/top-category-products',
    updatePosition: '/top-category-products/update-position',
  },
  catalogues: {
    all: '/admin/catalogues',
    brandsCatalogues: '/admin/brands/catalogues',
    catalogueById: '/admin/catalogues/catalogueId',
    updatePosition: '/admin/catalogues/:id/update-position',
    updatePriority: '/admin/catalogues/:catalogueId/update-priority',
  },
  trending: {
    all: '/trending',
    deleteTrendingProduct: '/trending/:id',
    updatePosition: '/trending/update-position',
  },
  topProducts: {
    all: '/top-products',
    deleteTopProduct: '/top-products/:id',
    updatePosition: '/top-products/update-position',
  },
  parserLogs: 'product-parser-failed-log',
  cms: {
    all: '/cms',
    byName: '/cms/:name',
  },
  editPages: {
    all: '/edit-pages',
    editPage: '/edit-pages/:id',
    products: '/edit-pages/:id/products',
    deleteProduct: '/edit-pages/:id/products/:productId',
    updatePosition: '/edit-pages/:id/products/:productId/update-position',
    pageById: '/edit-pages/:id',
  },
  dashboard: {
    getUserProfile: '/admin/user/:id/profile',
    getUserPayments: '/admin/user/:id/payments',
    getUserRecruits: '/admin/user/:id/signed-up-by-you',
  },
  analytics: {
    revenues: {
      getSales: '/analytics/sales',
      getCommission: '/analytics/commission',
      getCashback: '/analytics/cashback',
      getRevenues: '/analytics/revenue',
    },
    members: {
      getTotalMembers: '/analytics/members',
      getMembersTypes: '/analytics/members-types',
      getPaymentsTypes: '/analytics/payment-types',
    },
    membersUsage: {
      getTotalMembersBuyingItems: '/analytics/members-buying-items',
      getTotalActiveShoppingBoards: '/analytics/active-shopping-boards',
      getTotalShareLinksCreated: '/analytics/share-links-created',
      getTotalMembersUsingSite: '/analytics/members-using-site',
    },
  },
  revShare: {
    revShareList: '/admin/revshare',
    report: '/admin/revshare/report',
  },
};

export default ENDPOINTS;
